<template>
  <a @click="activateControl(row)" >
    <v-list-item data-type="deactivateExplorer">
      <v-list-item-title>
        <v-card class="pa-2 mt-3 elevation-0 delete">
          <div v-if="row['n_status'] == 1">
            <v-icon left>mdi-toggle-switch-off </v-icon>Deactivate Explorer
          </div>
          <div v-else><v-icon left>mdi-toggle-switch</v-icon>Activate Explorer</div>
        </v-card>
      </v-list-item-title>
    </v-list-item>
  </a>
</template>
<script>
  import { explorerActivation } from "@/js/helper.js";

  export default {
  components: {},
  props: ["info", "actionName", "currentTable", "row", "rowindex"],
/*  created() {
    this.$root.$refs.dectivateExplorer = this;
  },*/
  methods: {
    activateControl(row) {
      explorerActivation(row,this);
      /*      let status = 0;
           /*     if (row.n_status == 0) {
            status = 1;
          }
          let options = {
            function: "toggleExplorerStatus",
            n_id: this.row.n_id,
            n_id_key: this.row.n_id_key,
            status: status,
            dataType: "json",
            requestType: "ajax",
          };
          let caller = this;
          // let nID = row.n_id;
          // let rowSet = row;
          this.frameworkAxiosRequest({
            method: "POST",
            url: "serve.php?f=administration&f2=explorers",
            data: options,
          })
            .then(function () {
              //update the header extra
             // console.log(response);
            /!*  caller.$set(
                caller.currentTable,
                "data",
                response.data.result.table.data
              );
              caller.$store.commit("tableChanged", caller.currentTable);*!/
              let statusType = "all";
              if(typeof caller.currentTable.selectedStatus!='undefined'){
                statusType = caller.currentTable.selectedStatus;
              }
              caller.$root.$refs.explorerStatusFilter.getSelectedStatusData(statusType);
              console.log(caller.currentTable.selectedStatus);
              caller.$notify({
                group: "foo",
                text: "Successfully updated",
                duration:1000,
                type: "success",
                speed: 600,
              });
            })
            .catch(function (error) {
              //handle error
              console.log(error);
            });*/
    },
  },
};
</script>